@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Cusstom Styles */
.accordion-item.active .accordion-icon {
    transform: rotate(90deg);
  }

  .syntax-white{
color: #e1e4e8;
  }

  .syntax-red{
    color: #f97583;
  }

  .syntax-purple{
    color: #b392f0;
  }

  .syntax_blue{
    color: #9ecbff;
  }


  .syntax_default{
    color: #fff;
  }
  .syntax_operator{
    color: #fff;
  }
  .syntax_keyword{
    color: #b392f0;
  }
  .syntax_function{
    color: #00d4ff;
  }
  .syntax_punctuation{
    color: #fff;
  }
  .syntax_string{
    color: #ffa956;
  }
  .syntax_number{
    color: #ffd96a;
  }
 

  
  